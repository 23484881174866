// env_variables_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.triggerElement = this.element.querySelector("#triggerElement");
    this.popupElement = this.element.querySelector("#popupElement");
  }

  showPopup() {
    this.popupElement.classList.remove("hidden");
  }

  hidePopup() {
    this.popupElement.classList.add("hidden");
  }
}
