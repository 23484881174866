import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

// Connects to data-controller="simulation"
export default class extends Controller {
    static targets = ["map", "agent", "step", "agentDisplay", "agentDisplayTemplate", "play", "logs", "agentDropdown", "messageInput"]

    connect() {
        // Draggable map
        this.mapTarget.addEventListener("mousedown", this.startDrag.bind(this));

        // Zoomable map
        this.mapTarget.addEventListener("wheel", this.handleScale.bind(this));

        // Arrow key step control
        document.addEventListener('keydown', (event) => {
            const isWithinScenarioForm = event.target.closest('.scenario-form');

            if (!isWithinScenarioForm) {
                if (event.key === 'ArrowLeft' || event.key === 'q') {
                    this.subtractStep(event);
                }
                else if (event.key === 'ArrowRight' || event.key === 'e') {
                    this.addStep(event);
                }
                else if (event.key === 'w' || event.key === 'a' || event.key === 's' || event.key === 'd') {
                    this.handleWASD(event);
                }
                else if (event.key === ' ' || event.code === 'Space') {
                    event.preventDefault();
                    this.togglePlay(event);
                }
                else if (event.key === 'f' || event.key === 'v') {
                    this.handleScale(event);
                }
                else if (event.key === 'r') {
                    this.replay(event);
                }
            }
        });
        this.setupInitialAgent()
          .then(firstAgentName => {
              console.log('First Agent Name:', firstAgentName);
              this.displayAgentData({
                  currentTarget: { dataset: { id: firstAgentName } },
              });
          })
          .catch(error => {
              console.error('Error setting up initial agent:', error);
          });
        this.scaleValue = 1;
        this.isAnimating = false;
        this.isPlaying = false;
        if (this.agentDropdown) {
            this.populateAgentDropdown();
        }
        this.togglePlay();
    }
  
    setupInitialAgent() {
      return new Promise((resolve, reject) => {
          const agentKeys = document.querySelectorAll('[data-simulation-target="agent"]');
          const agentNames = Array.from(agentKeys)
              .map((agentKey) => agentKey.dataset.id)
              .filter((agentName) => !agentName.includes('Zombie'));

          const firstAgentName = agentNames[0];
          if (firstAgentName) {
              resolve(firstAgentName);
          } else {
              reject(new Error('No valid agent names found.'));
          }
      });
    }
  
    populateAgentDropdown() {
      const agentKeys = document.querySelectorAll('[data-simulation-target="agent"]');
      const agentNames = Array.from(agentKeys)
        .map((agentKey) => agentKey.dataset.id)
        .filter((agentName) => !agentName.includes('Zombie'));

      // Example: Assuming you have a dropdown element with ID "agentDropdown"
      const dropdown = document.getElementById('agentDropdown');

      // Clear existing options
      dropdown.innerHTML = '';

      // Create and append new options based on agent names
      agentNames.forEach((agentName) => {
        const option = document.createElement('option');
        option.value = agentName;
        option.text = agentName;
        dropdown.appendChild(option);
      });
    }
    sendMessage() {
      const selectedAgent = this.agentDropdownTarget.value;
      const message = this.messageInputTarget.value;

      if (selectedAgent && message) {
        // Push the message to the Redis queue
        this.pushMessageToQueue(selectedAgent, message);
      } else {
        console.error("Agent and message are required");
      }
    }
    pushMessageToQueue() {
      const selectedAgent = this.agentDropdownTarget.value;
      const message = this.messageInputTarget.value;
      const urlParams = new URLSearchParams(window.location.search);
      const simId = urlParams.get("sim_id");
      console.log('Selected Agent:', selectedAgent);
      console.log('Message:', message);

      if (selectedAgent && message) {
        // Send the message to the Rails API endpoint
        fetch('/send_command', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // You may need to include additional headers if required by your API
          },
          body: JSON.stringify({
            name: selectedAgent,
            msg: message,
            sim_id: simId, // Add your simulation ID here
          }),
        })
          .then(response => {
            if (response.ok) {
              console.log(`Message sent to ${selectedAgent}: ${message}`);
            } else {
              console.error('Failed to send message:', response.statusText);
            }
          })
          .catch(error => {
            console.error('Error sending message:', error);
          });
      } else {
        console.error('Agent and message are required');
      }
    }

    replay(event) {
        this.stepTarget.value = 1;
        this.togglePlay(event);
    }

    togglePlay(event) {
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
            this.playTarget.classList.remove("fa-play");
            this.playTarget.classList.add("fa-pause");
        }
        else {
            this.playTarget.classList.remove("fa-pause");
            this.playTarget.classList.add("fa-play");
        }
        this.startPlaying(event);
    }

    startPlaying(event) {
        const playNextStep = async () => {
            console.log("Playing...");
            if (this.isPlaying && !this.isAnimating) {
                this.stepTarget.value = parseInt(this.stepTarget.value) + 1;
                this.playSimulation();
            }
        };

        if (this.isPlaying) {
            this.playInterval = setInterval(playNextStep, 500);
        } else {
            clearInterval(this.playInterval);
        }
    }

    startDrag(event) {
        event.preventDefault();

        const offsetX = event.clientX - this.mapTarget.offsetLeft;
        const offsetY = event.clientY - this.mapTarget.offsetTop;

        const moveHandler = (e) => {
            this.mapTarget.style.left = e.clientX - offsetX + "px";
            this.mapTarget.style.top = e.clientY - offsetY + "px";
        };

        const stopHandler = () => {
            document.removeEventListener("mousemove", moveHandler);
            document.removeEventListener("mouseup", stopHandler);
        };

        document.addEventListener("mousemove", moveHandler);
        document.addEventListener("mouseup", stopHandler);
    }

    handleScale(event) {
        event.preventDefault();

        let deltaVal = 0;
        if (event.key === 'f') {
            deltaVal = 0.01;
        }
        else if (event.key === 'v') {
            deltaVal = -0.01;
        }
        else {
            this.scaleValue += event.deltaY > 0 ? -0.01 : 0.01;
        }

        // Adjust the scale based on the direction of the scroll
        this.scaleValue += deltaVal;

        // Limit the scale to a minimum value of 0.25
        this.scaleValue = Math.max(0.25, this.scaleValue);

        // Apply the scale to the mapTarget
        this.mapTarget.style.transform = `scale(${this.scaleValue})`;
    }

    handleWASD(event) {
        switch (event.key) {
            case 's':
                this.mapTarget.style.top = parseInt(this.mapTarget.style.top) - 10 + "px";
                break;
            case 'w':
                this.mapTarget.style.top = parseInt(this.mapTarget.style.top) + 10 + "px";
                break;
            case 'd':
                this.mapTarget.style.left = parseInt(this.mapTarget.style.left) - 10 + "px";
                break;
            case 'a':
                this.mapTarget.style.left = parseInt(this.mapTarget.style.left) + 10 + "px";
                break;
        }
    }

    move(agentId, newTop, newLeft) {
      const agentElement = this.agentTargets.find((el) => el.dataset.id === agentId);
      if (agentElement) {
        const currentTop = parseFloat(agentElement.style.top || 0);
        const currentLeft = parseFloat(agentElement.style.left || 0);

        anime({
          targets: agentElement,
          top: [`${currentTop}px`, `${newTop * 32}px`],
          left: [`${currentLeft}px`, `${newLeft * 32}px`],
          easing: "easeInOutQuad",
          duration: 300,
        });

        // Check agent status and show/hide crossbone image
        this.updateAgentStatus(agentElement);
      }
    }

    updateAgentStatus(agentElement, status) {
      if (agentElement) {
        if (status === "dead") {
          // Check if crossbone image is already present
          const crossBoneImage = agentElement.querySelector('.dead-status-image');
          if (!crossBoneImage) {
            // Add crossbone image dynamically
            const crossBoneImage = document.createElement('img');
            crossBoneImage.src = '/assets/cross.png'; // Adjust the path accordingly
            crossBoneImage.className = 'dead-status-image';
            crossBoneImage.style.position = 'absolute';
            crossBoneImage.style.top = '0';
            crossBoneImage.style.left = '0'; // Adjust the left position accordingly
            crossBoneImage.style.height = '35px';
            crossBoneImage.style.width = '35px';
            crossBoneImage.style.zIndex = '5';
            agentElement.appendChild(crossBoneImage);
          }
        } else {
          // Remove crossbone image if agent is not dead
          const crossBoneImage = agentElement.querySelector('.dead-status-image');
          if (crossBoneImage) {
            crossBoneImage.remove();
          }
        }
      }
    }

    addStep(event) {
        if (!this.isAnimating) {
            this.stepTarget.value = parseInt(this.stepTarget.value) + 1;
            this.stepChanged(event);
        }
    }

    subtractStep(event) {
        if (!this.isAnimating) {
            this.stepTarget.value = parseInt(this.stepTarget.value) - 1;
            this.stepChanged(event);
        }
    }

    stepChanged(event) {
        if(this.isAnimating) {
            console.log("Currently playing animation.");
            return;
        }
        if (parseInt(this.stepTarget.value) < 1) {
            console.log("Can't have step < 1");
            this.stepTarget.value = 1;
            return;
        }

        this.playSimulation();
    }

    playSimulation() {
      this.isAnimating = true;
      this.stepTarget.disabled = true;
      this.getSimulationData()
        .then((data) => {
          for (const [name, arr] of Object.entries(data["agents"])) {
            const agentElement = this.agentTargets.find((el) => el.dataset.id === name);

            if (agentElement) {
              if (Array.isArray(arr) && arr.length < parseInt(this.stepTarget.value)) {
                this.stepTarget.value = arr.length;
                console.error(`Simulation is only until ${arr.length}`);
                return;
              } else if (Array.isArray(arr) && arr.length > 0) {
                const elementString = arr[parseInt(this.stepTarget.value) - 1];
                try {
                  const elementJSON = JSON.parse(elementString);
                  this.move(name, elementJSON.x, elementJSON.y);

                  // Update agent status and show/hide crossbone image
                  this.updateAgentStatus(agentElement, elementJSON.status);
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              } else {
                console.log("No data for this agent.");
              }
            }
          }

          for (let i = this.logsTarget.querySelectorAll('li').length - 1; i < data["logs"].length; i++) {
            const listItem = document.createElement('li');
            listItem.textContent = data["logs"][i];
            listItem.classList.add('text-black');
            this.logsTarget.appendChild(listItem);
          }
        })
        .catch((error) => {
          console.error("Error fetching simulation data:", error);
        })
        .finally(() => {
          this.isAnimating = false;
          this.stepTarget.disabled = false;
        });
    }

    getSimulationData() {
        return new Promise(async (resolve, reject) => {
            const urlParams = new URLSearchParams(window.location.search);
            const simId = urlParams.get("sim_id");
            try {
                const response = await fetch(`/fetch_simulation_data?sim_id=${simId}`);
                const data = await response.json();

                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    displayAgentData(event) {
        const agentName = event.currentTarget.dataset.id;
        let agentStatus = ""
        let agentX = ""
        let agentY = ""
        let agentConversations = [];
        let agentEvents = [];
        this.getSimulationData()
            .then(data => {
                // Get agent data
                const agentData = data["agents"][agentName];
                if (Array.isArray(agentData) && agentData.length < parseInt(this.stepTarget.value)) {
                    this.stepTarget.value = agentData.length;
                    console.error(`Simulation is only until ${agentData.length}`);
                    return;
                }
                else if (Array.isArray(agentData) && agentData.length > 0) {
                    const elementString = agentData[parseInt(this.stepTarget.value) - 1];

                    try {
                        const elementJSON = JSON.parse(elementString);
                        agentStatus = elementJSON.status;
                        agentX = elementJSON.x;
                        agentY = elementJSON.y;
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                }
                // Get agent conversations
                agentConversations = data["conversations"][agentName];
                agentEvents = data["events"][agentName];

                const template = document.importNode(this.agentDisplayTemplateTarget.content, true);
                template.querySelector('[data-id="name"]').textContent = agentName;
                template.querySelector('[data-id="status"]').textContent = agentStatus;
                //template.querySelector('[data-id="position"]').textContent = `Position: x=${agentX}, y=${agentY}`;

                const conversationsList = template.querySelector('[data-id="conversations"]');
                agentConversations.forEach(conversation => {
                    const listItem = document.createElement('li');
                    listItem.textContent = conversation;
                    listItem.classList.add('text-black');
                    conversationsList.appendChild(listItem);
                });
                const eventsList = template.querySelector('[data-id="events"]');
                agentEvents.forEach(eventString => {
                    const listItem = document.createElement('li');
                    listItem.textContent = eventString;
                    listItem.classList.add('text-black');
                    eventsList.appendChild(listItem);
                });
                this.agentDisplayTarget.innerHTML = '';
                this.agentDisplayTarget.appendChild(template);
            })
            .catch(error => {
                console.error("Error fetching simulation data:", error);
            })
            .finally(() => {
              console.log("Agent Details displayed");
            });
    }
}
